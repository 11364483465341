import React, { useEffect, useState } from 'react';
import SalmonButton from '../../designSystem/SalmonButton';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Pdf from '../../assets/pdf.svg';
import ExternalLink from '../../assets/external-link.svg';
import { Checkbox, FormControlLabel } from '@mui/material';
import { track } from '@amplitude/analytics-browser';
import { AmplitudeEvents } from '../../utils/amplitudeEvents';

function CashLoanContract() {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const applicationNumber = searchParams.get('applicationNumber');
	const contractUrl = searchParams.get('contractUrl');
	const [checked, setChecked] = useState(false);

	useEffect(() => {
		track(AmplitudeEvents.SignContract_Opened);
	}, []);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setChecked(event.target.checked);
		track(AmplitudeEvents.IHaveRead_Tap, {
			state_after_tap: event.target.checked ? 'agree' : 'disagree',
		});
	};

	// todo if doesn't exist applicationNumber or contractUrl go to some error page

	return (
		<div>
			<h5
				style={{
					fontFamily: 'Nantes',
					fontStyle: 'normal',
					fontWeight: 700,
					fontSize: '28px',
					lineHeight: '28px',
					marginTop: '16px',
					marginBottom: '12px',
				}}
			>
				Cash Loan contract
			</h5>

			<div
				style={{
					fontStyle: 'normal',
					fontWeight: 500,
					fontSize: '16px',
					lineHeight: '22px',
					marginBottom: '36px',
				}}
			>
				Make sure to read and understand the contract before signing it with an OTP
			</div>

			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					cursor: 'pointer',
				}}
				onClick={() => {
					track(AmplitudeEvents.SalmonLoanAgreement_Tap);
					window.open(contractUrl as string, '_blank');
				}}
			>
				<img src={Pdf} alt="logo" width={21} height={26} />
				<div
					style={{
						fontFamily: 'Gilroy',
						fontStyle: 'normal',
						fontWeight: 600,
						fontSize: '16px',
						lineHeight: '22px',
						margin: '0 10px 0 12px',
					}}
				>
					Salmon LoanOnline agreement
				</div>
				<img src={ExternalLink} alt="logo" width={21} height={26} />
			</div>

			<div
				style={{
					padding: '16px',
					border: '1px solid #D7D7D7',
					borderRadius: '12px',
					marginTop: '29px',
					marginBottom: '24px',
				}}
			>
				<FormControlLabel
					value="start"
					control={
						<Checkbox
							checked={checked}
							onChange={handleChange}
							sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
						/>
					}
					label="I have read and agree with the terms and conditions of this contract"
					labelPlacement="start"
				/>
			</div>

			<SalmonButton
				variant={'contained'}
				disabled={!checked}
				fullWidth
				onClick={() => {
					track(AmplitudeEvents.ProceedToSign_Tap);
					navigate({
						pathname: '/sign-contract',
						// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
						search: `?applicationNumber=${applicationNumber!}`,
					});
				}}
			>
				Sign contract with OTP
			</SalmonButton>
		</div>
	);
}

export default CashLoanContract;
